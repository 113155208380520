<template>
  <div style="height: 600px">
    <iframe frameborder="0"
      :src="useIframeUrl(faData.needOpenId)"
      width="100%" height="100%">
      </iframe>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import { useIframeUrl } from '@/reactivity/detailIframeUrl';
export default defineComponent({
  setup() {
    const faData = inject('needIframeData');
    return {
      faData,
      useIframeUrl,
    };
  },
});
</script>
