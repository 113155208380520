import {
  CallBackUrlTest,
  CallBackUrl,
  CallBackUrlPre,
} from '../config/login/index';
const { href } = location;
console.log(process.env, 'process.env');

let evn = '';
if (href.indexOf(CallBackUrlTest) !== -1 || href.indexOf(CallBackUrl) !== -1) {
  evn = 'test';
} else if (href.indexOf(CallBackUrlPre) !== -1) {
  evn = 'pre';
} else {
  evn = 'prd';
}

export function useIframeUrl(id: string): string {
  const IframeUrlPrd = 'https://qzs.qq.com/open/yyb/community/index.html#/topicDetail/'; // 生产
  const IframeUrlPre = 'http://static.kf0309.3g.qq.com/open/yyb/community_pre/index.html#/topicDetail/'; // 预发布
  const IframeUrlTest = 'http://static.kf0309.3g.qq.com/open/yyb/community/index.html#/topicDetail/'; // 测试
  let iframeUrl = '';
  // iframeUrl = evn === 'test' ?  `${IframeUrlTest + id}` : `${IframeUrlPre + id}`;
  switch (evn) {
    case 'test':
      iframeUrl = `${IframeUrlTest + id}`;
      break;
    case 'pre':
      iframeUrl = `${IframeUrlPre + id}`;
      break;
    case 'prd':
      iframeUrl = `${IframeUrlPrd + id}`;
      break;
  }
  return iframeUrl;
}
