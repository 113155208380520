
import { request } from '@/services';
import { Request } from '@/services/requests/request';
import { AxiosRequestConfig } from 'axios';
import { AnyObject } from 'element-plus/lib/el-table/src/table.type';

interface resultInterface<T> {
  ret: number;
  msg: string;
  data: T | AnyObject;
}

interface IgetPostList {
  communityId?: number;
  type?: number;
  tagId?: number;
  rule: number;
  pageNum: number;
  pageSize: number;
  lastPostId?: number;
  creatorId?: string;
  parentPostId?: number;
  ceartorNickName?: string;
  uin?: number;
  communityName?: string;
  beginTime?: number;
  endTime?: number;
  status: string;
  loginUserId?: string;
  postId?: number;
}
interface postListInterface  {
  posts: {
    id: number;
    communityId: number;
    title: string;
    content: string;
    desc: string;
    creatorId: string;
    createTime: number;
    status: number;
    tagId: number;
    auditorId: string;
    auditTime: number;
    isTop: number;
    topTime: number;
    parentPostId: number;
    lastReplyTime: number;
    isGood: number;
    isOfficial: number;
    pv: number;
    replyNum: number;
    likeNum: number;
    shareNum: number;
    creatorNickName: string;
    creatorIcon: string;
    coverImgUrl: string;
    isLike: number;
    communityName: string;
  }[];
  pageInfo: {
    pageNum: number;
    pageSize: number;
    total: number;
    totalPage: number;
  };
}

export function getPostList(req: IgetPostList):
Promise<Request<AxiosRequestConfig, resultInterface<postListInterface>>> {
  return request<AxiosRequestConfig, resultInterface<postListInterface>>({
    url: '/api/manage/post/query?rule=4',
    method: 'get',
    params: req,
  });
}

// 新增帖子接口
interface addNewPost {
  title: string;
  content: string;
  tagId: number;
  parentPostId: number;
  postCategory: number;
  coverUrl: string;
  desc: string;
  communityId: number;
  postType: number;
  materialList: {
    type: number;
    width: number;
    height: number;
    url: string;
    videoId: string;
  }[];
}
interface addNewRetrun {
  postId: number;
}

export function addNewPost(req: addNewPost):
Promise<Request<AxiosRequestConfig, resultInterface<addNewRetrun>>> {
  return request<AxiosRequestConfig, resultInterface<addNewRetrun>>({
    url: '/api/manage/post/create',
    method: 'post',
    data: { ...req },
  });
}

// 编辑帖子接口
interface addEditorPost {
  post_id: number;
  title: string;
  content: string;
  tag_id: number;
  post_type: number;
  parent_post_id: number;
  cover_url: string;
  desc: string;
  material_list: {
    type: number;
    width: number;
    height: number;
    url: string;
    video_id: string;
  }[];
}
interface addEditorRetrun {
  postId: number;
}
export function editorPost(req: addEditorPost):
Promise<Request<AxiosRequestConfig, resultInterface<addEditorRetrun>>> {
  return request<AxiosRequestConfig, resultInterface<addEditorRetrun>>({
    url: '/api/manage/post/edit',
    method: 'post',
    data: req,
  });
}

// 根贴ID验证接口
interface IcheckPost {
  postId: number;
  userId: number;
}
interface IcheckPostReturn {
  creatorInfo: {
    icon: string;
    nickName: string;
    creatorId: string;
  };
  postInfo: {
    title: string;
    content: string;
    id: number;
    createTime: number;
    status: number;
    tagId: number;
    parentPostId: number;
    lastReplyTime: number;
    isLiked: boolean;
    likeNum: number;
    replyNum: number;
    isLike: boolean;
  };
  communityInfo: {
    communityName: string;
    basicUserNum: number;
    basicHotNum: number;
    communityId: number;
    icon: string;
    appid: number;
  };
}
export function checkPost(req: IcheckPost):
Promise<Request<AxiosRequestConfig, resultInterface<IcheckPostReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IcheckPostReturn>>({
    url: '/api/post/get_posts_detail',
    method: 'get',
    params: req,
  });
}

// 图片上传数据接口
interface IupImg {
  file: string;
}
interface IupImgReturn {
  file_url: string;
}
export function upData(req: IupImg):
Promise<Request<AxiosRequestConfig, resultInterface<IupImgReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IupImgReturn>>({
    url: '/api/upload',
    method: 'post',
    data: req,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}


// 帖子管理参数接口
interface IpostManage {
  manageType: number;
  postId: number;
  reason: string;
}
interface IpostManageReturn {
  ret: number;
  msg: string;
}

export function postManage(req: IpostManage):
Promise<Request<AxiosRequestConfig, resultInterface<IpostManageReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IpostManageReturn>>({
    url: '/api/manage/post/manage',
    method: 'post',
    data: req,
  });
}

// 	获取用户信息接口
interface IgetUserInfo {
  user_id: string;
}
interface IgetUserInfoReturn {
  user_id: string;
  login_type: number;
  nick_name: string;
  icon: string;
}

export function getUser(req: IgetUserInfo):
Promise<Request<AxiosRequestConfig, resultInterface<IgetUserInfoReturn>>> {
  return request<AxiosRequestConfig, resultInterface<IgetUserInfoReturn>>({
    url: '/api/user/query_info',
    method: 'post',
    data: req,
  });
}


